<template>
  <div class="container" align="center">
    <div class="d-flex justify-content-center">
      <img
        src="/images/logo.jpg"
        alt=""
        width="650"
        height="300"
        style="display: block; margin: auto;"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";

export default {
  data() {
    return {
      token: "",
      mensaje: "Estamos validando el registro de su cuenta",
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    //console.log(this.$route)
    this.redirectLogin();
  },
  methods: {
    redirectLogin() {
      let alert = swal.fire({
        title: "Estamos validando el token de registro...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      axios
        .post(process.env.VUE_APP_URL_ACTIVATE, {
          token: this.token,
        })
        .then((res) => {
          alert.close();
          if (res.data.status) {
            swal.fire({
              title: "Cuenta Activada",
              text: "Te estamos redirigiendo a nuestro portal.",
              icon: "success",
              timer: 2000,
              onClose: () => {
                this.mensaje = "Su cuenta ha sido activada exitosamente.";
                window.open(res.data.link, "_self");
              },
            });
          } else {
            swal.fire({
              title: "Ha ocurrido un error",
              text: "Ha ocurrido un error al realizar la activación.",
              icon: "error",
              timer: 2000,
              onClose: () => {
                this.mensaje =
                  "Su cuenta ya ha sido creada con anterioridad por favor dirijase a nuestra pagina principal";
              },
            });
          }
        })
        .catch((err) => {
          alert.close();
          console.log(err);
          swal.fire({
            title: "Ha ocurrido un error",
            text: "Ha ocurrido un error al realizar la activación.",
            icon: "error",
            timer: 2000,
          });
          this.mensaje = "Su token ha expirado";
          //window.location.href = 'http://www.cellvoz.com';
        });
    },
  },
};
</script>
